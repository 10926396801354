
import Vue from 'vue'
import { Control } from '@/code/project';

  export default Vue.extend({
    name: 'Imgx',

    props: {
      control: Control
    },

    computed: {
      defaultImage() {
        return require('@/assets/img.png');
      }
    }
  })
